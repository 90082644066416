import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import miniLogo from "../../assets/imgs/mini-logo.svg";
import { useTranslation } from 'react-i18next';
export default function FooterAuth() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Container>
        <Typography
          variant="body2"
          align="center"
          className={classes.poweredBy}
          onClick={() =>
            window.open(
              "http://www.tacticalmortgagesolutions.com",
              "__blank",
              "noopener,noreferrer"
            )
          }
        >
          {t("powered_by")} <img src={miniLogo} className={classes.footerLogo} />
        </Typography>
      </Container>
    </footer>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    paddingBottom: "20px",
    paddingTop: "20px",
    textAlign: "center",
    alignItems: "center",
  },
  poweredBy: {
    // margin: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "#05304E",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 400,
    cursor: "pointer",
    [theme.breakpoints.down(1080)]: {
      justifyContent: "flex-start",
      marginLeft: "150px",
    },
  },
  footerLogo: {
    width: 80,
    marginLeft: 5,
  },
}));
