import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { user } from "../App";
import { IRoute } from "./config";
import Footer from "../@components/footer/Footer";
import FooterAuth from "../@components/footer/FooterAuth";

const RouteWithSubRoutes = (route: IRoute) => {
  /** Authenticated flag */
  const authenticated: boolean = useSelector((state: any) => state.auth.token);
  return (
    <Suspense fallback={route.fallback}>
      <Route
        path={route.path}
        render={(props) =>
          route.redirect ? (
            <Redirect to={route.redirect} />
          ) : route.private ? (
            authenticated ? (
              route.component && (
                <>
                  <route.component {...props} routes={route.routes} />
                  {route.footer && <Footer />}
                </>
              )
            ) : (
              <Redirect to="/login" />
            )
          ) : (
            route.component && (
              <>
                <route.component {...props} routes={route.routes} />
                {route.footer && <FooterAuth />}
              </>
            )
          )
        }
      />
    </Suspense>
  );
};

export default RouteWithSubRoutes;
