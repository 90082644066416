const APP_CONFIG = {
  // STAGING SERVER
  // API_URL: "https://tms-staging-admin.thedemo.co/api/",
  // BASE_URL: "https://tms-staging-admin.thedemo.co/",

  // DEV SERVER
  API_URL: "https://api.tms.247demo.ca/api/",
  BASE_URL: "https://api.tms.247demo.ca/",
  
  // LOCAL SERVER
  // API_URL: "http://localhost:8000/api/",
  // BASE_URL: "http://localhost:8000/",

  // APP_VERSION: "V 0.7.2",
  APP_VERSION: "V 0.9.8",

};

export default APP_CONFIG;
