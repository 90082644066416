import React, {
  ComponentType,
  lazy,
  LazyExoticComponent,
  ReactNode,
} from "react";
import loader from "../assets/imgs/loader.gif";
const Spinner = (
  <div style={{ height: "100vh", backgroundColor: "white" }}>
    <img
      src={loader}
      alt="loading..."
      style={{
        backgroundPosition: "center",
        position: "absolute",
        left: "40%",
        top: "35%",
        width: "20%",
      }}
    />
  </div>
);
export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
  footer?: boolean;
}

export const routes: IRoute[] = [
  {
    path: "/",
    exact: true,
    redirect: "/home",
    fallback: <div> {Spinner} </div>,
    // footer : true,
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/onboarding/Login")),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
  },
  {
    path: "/contact-invitation/:id",
    component: lazy(() => import("../pages/onboarding/ContactInvitation")),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
  },

  {
    path: "/sign-up",
    component: lazy(() => import("../pages/onboarding/Signup")),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
    // routes: [
    //   {
    //     path: "/signup/step2",
    //     component: lazy(() => import("../pages/onboarding/SignupStepTwo")),
    //     exact: true,
    //     private: true,
    //     fallback: <div> {Spinner} </div>,
    //   },
    // ],
  },
  {
    path: "/create-account",
    component: lazy(() => import("../pages/onboarding/SignupStepTwo")),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
  },
  {
    path: "/verification-pending",
    component: lazy(() => import("../pages/onboarding/VerifyEmail")),

    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/verify-email",
    component: lazy(() => import("../pages/onboarding/AccountVerified")),

    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/success",
    component: lazy(() => import("../pages/onboarding/PaymentSuccess")),

    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/forgotpassword",
    component: lazy(() =>
      import("../pages/onboarding/PasswordReset/ForgotPassword")
    ),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/enter-code/:email",
    component: lazy(() =>
      import("../pages/onboarding/PasswordReset/EnterCode")
    ),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/enter-new-password/:pin/:email",
    component: lazy(() =>
      import("../pages/onboarding/PasswordReset/EnterNewPassword")
    ),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/referral/:referal_id",
    component: lazy(() =>
      import("../pages/onboarding/Signup")
      // import("../pages/onboarding/PasswordReset/EnterNewPassword")
    ),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/confirmation-screen",
    component: lazy(() =>
      import("../pages/onboarding/PasswordReset/ConfirmationMessage")
    ),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
  },
  {
    path: "/plans",
    component: lazy(() => import("../pages/plans")),
    exact: true,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/plans/all",
    component: lazy(() => import("../pages/plans/AllPrices")),
    exact: true,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/expire-trial",
    component: lazy(() => import("../pages/trailexpire/TrailExpire")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/delete-account",
    component: lazy(() => import("../pages/deleteaccount/DeleteAccount")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/delete-feedback",
    component: lazy(() => import("../pages/deleteaccount/DeleteFeedback")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/delete-success",
    component: lazy(() => import("../pages/deleteaccount/SuccessfulDelete")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/payment",
    component: lazy(() => import("../pages/payment")),
    exact: true,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
  },
  // {
  //   path: "/edit-payment",
  //   component: lazy(() => import("../pages/payment")),
  //   exact: true,
  //   private: false,
  //   fallback: <div> {Spinner} </div>,
  //   footer: false,
  // },
  {
    path: "/contact",
    component: lazy(() => import("../pages/contactus")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/contact/:id",
    // path: "/contact/single-contact/:id",
    component: lazy(() => import("../pages/eachContact")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/create-contact",
    component: lazy(() => import("../pages/contacts/createContact")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/edit-contact",
    component: lazy(() => import("../pages/contacts/editContact")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/rate-comparison",
    component: lazy(() => import("../pages/rateComparison")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/create-rate-plan",
    component: lazy(() => import("../pages/rateComparison/CreatePlan")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/edit-rate-plan",
    component: lazy(() => import("../pages/rateComparison/CreatePlan")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/account",
    component: lazy(() => import("../pages/account")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/account/change-password",
    component: lazy(() => import("../pages/account/change-password")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/account/insurance",
    component: lazy(() => import("../pages/account/insurance")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/account/team-members",
    component: lazy(() => import("../pages/account/team-members")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/account/team-members/purchase-team-member",
    component: lazy(() => import("../pages/account/team-members/purchase-team-member")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    // path: "/account/company/settings",
    path: "/account/company",
    component: lazy(() => import("../pages/account/company/company-settings")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/account/theme-color",
    component: lazy(() => import("../pages/account/company/ThemeColor")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/join-company/:id",
    component: lazy(() => import("../pages/onboarding/MemberInvitation")),
    exact: false,
    private: false,
    fallback: <div> {Spinner} </div>,
    footer: false,
  },

  {
    // path: "/account/company/payment-details",
    path: "/account/billing",
    // component: lazy(() => import("../pages/account/company/Billing")),
    component: lazy(() => import("../pages/account/company/payment-details-wrapper")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/account/company/members/:id/details",
    component: lazy(() => import("../pages/account/company/members/details")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/support",
    component: lazy(() => import("../pages/support")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/refer",
    component: lazy(() => import("../pages/refer")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/support-view/:id",
    component: lazy(() => import("../pages/support/SupportView")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/learn-more",
    component: lazy(() => import("../pages/support/LearnMore")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/privacy-policy",
    component: lazy(() => import("../pages/privacyPolicy")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() => import("../pages/termsAndConditions")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/home",
    component: lazy(() => import("../pages/layouts/dashboard")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,

    // routes: [
    //     {
    //         path: '/home/signup',
    //         component: lazy(() => import('../')),
    //         exact: false,
    //         private: false,
    //         fallback: <div> Loading... </div>
    //     },
    //     {
    //         path: '/home/login',
    //         component: lazy(() => import('../components/Login')),
    //         exact: false,
    //         private: false,
    //         fallback: <div> Loading... </div>
    //     }
    // ]
  },
  {
    path: "/strategy-session/:id",
    component: lazy(() => import("../pages/layouts/strategySession")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/strategy-session/:tabName/:id/",
    component: lazy(() => import("../pages/layouts/strategySession")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },

  {
    path: "/strategy-session/:tabName/:id/:cardId",
    component: lazy(() => import("../pages/layouts/strategySession")),
    exact: true,
    private: true,
    fallback: <div> {Spinner} </div>,
    footer: true,
  },
];
