const initialState = 0

export function activeTabReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case 'ACTIVE_TAB':
      return payload
    default:
      return state;
  }
}  