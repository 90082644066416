const initialState = 0

export function firstRenderReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case 'FIRST_RENDER':
      return payload
    default:
      return state;
  }
}  