import indigo from "@material-ui/core/colors/indigo";

import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7FB0EF",
      main: "#106EE8",
      dark: "#0E51A8",
      contrastText: "#fff",
    },
    secondary: {
      light: "#7FB0EF",
      main: "#373A40",
      dark: "#0E51A8",
      contrastText: "#fff",
    },
    success: {
      light: "#0BAC4F",
      main: "#0BAC4F",
      dark: "#0BAC4F",
      contrastText: "#fff",
    },
    error: {
      light: "#DF5B20",
      main: "#DF5B20",
      dark: "#DF5B20",
      contrastText: "#fff",
    },
  },
});

export default theme;
