import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import loader from '../assets/imgs/loader.gif';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
      height: "100vh",
      backgroundColor : 'white'
  },
  image: {
      backgroundPosition: "center",
      position: 'absolute',
          left: '40%',
          top: '35%',
          width: '20%',
  },
  text: {
      backgroundPosition: "center",
      position: 'absolute',
          left: '40%',
          top: '30%',
          width: '20%',
          color: '#1772e9',
          fontWeight: 'bold',
          fontSize: '18PX',
          fontFamily: 'Poppins'
  },
}));
export default function Spinner({message}) {
  const classes = useStyles();
  return ( <div className={classes.root} >
    <img src={loader} alt="loading..." className={classes.image} />
    <Typography className={classes.text}> {message} </Typography>
    </div>
  );
}