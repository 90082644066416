import React, { Component, useEffect } from "react";
import { routes } from "./@router/config";
import Router from "./@router/Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/globals.scss";
export const user = {
  authenticated: false,
};
import { useSelector } from "react-redux";
import { ConfigureLoader } from "./@core/axios";
import Spinner from "./@components/Spinner";

import { hotjar } from "react-hotjar";
import { makeStyles } from "@material-ui/core";
import useGaTracker from "./@components/useGaTracker";
hotjar.initialize(2824216, 6);

const App = () => {
  const loading = useSelector((state: any) => state.loading);
  ConfigureLoader(); //
  useGaTracker();

  let style = { display: !loading ? "block" : "none" };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss={false}
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      {loading && <Spinner message=''/>}
      {
        <div style={style}>
          <Router routes={routes} />
        </div>
      }
    </div>
  );
};
export default App;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    minWidth: 800,
    maxWidth: 1920,
    // [theme.breakpoints.up("lg")]: {
    //   maxWidth: 1300,
    //   width: "auto",
    //   minWidth: 1150,
    // },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 1920,
      width: "auto",
      // minWidth: 1100,
    },
  },
}));
