
const loader = (state = false, action: any = {}) => {
  switch (action.type) {
      case "SHOW_LOADER":
          return false;
      case "HIDE_LOADER":
          return false;
      default:
          return state;
  }
}

export default loader;