import apiRequest from "../../@core/axios";

const SET_TRAINING_RESOURCES = 'SET_TRAINING_RESOURCES'
const SET_EXTERNAL_LINKS_RESOURCES = 'SET_EXTERNAL_LINKS_RESOURCES'
const SET_HOME_PURCHASE_RESOURCES = 'SET_HOME_PURCHASE_RESOURCES'
const SET_CURRENT_TAG = 'SET_CURRENT_TAG'

export const initialState = {
    current_tag_name: 'dashboard',
    videoTutorials: {
        home_purchase: [],
        current_tag:[]
    },
    externalLinks: {
        home_purchase: [],
        current_tag:[]
    }

}

export function TrainingResourcesReducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case "SET_CURRENT_TAG":
            // console.log(`action: ${JSON.stringify(action.payload[0].data.data.tutorials)}`)
            let str = action.payload[0]?.config?.url
            let current_tag_name = str.includes('=') ? str.split('=')[1] : 'dashboard'
            // console.log({current_tag_name})
            return {
                ...state,
                current_tag_name,   
                videoTutorials: {
                    ...state.videoTutorials,
                    current_tag: action.payload[0].data.data.tutorials,   
                },             
                externalLinks: {
                    ...state.externalLinks,
                    current_tag: action.payload[1].data.data.tutorials,   
                },             
            };

        case "SET_TRAINING_RESOURCES":
            // console.log(`action: ${JSON.stringify(action.payload[0].data.data.tutorials)}`)
            return {
                ...state,   
                videoTutorials: {
                    home_purchase: action.payload[0].data.data.tutorials,   
                },             
            };

        case "SET_EXTERNAL_LINKS_RESOURCES":
            // console.log(`action: ${JSON.stringify(action.payload[0].data.data.tutorials)}`)
            return {
                ...state,   
                externalLinks: {
                    home_purchase: action.payload[0].data.data.tutorials,   
                }              
            };

        case "SET_HOME_PURCHASE_RESOURCES":
            return {
                ...state,
                videoTutorials: {
                    ...state.videoTutorials,
                    home_purchase: action.payload.data.data.tutorials,             
                } 
            };
        case "SET_HOME_PURCHASE_EXTERNAL_LINKS":
            return {
                ...state,
                externalLinks: {
                    ...state.externalLinks,
                    home_purchase: action.payload.data.data.tutorials,             
                },  
            };

        default:
            return state;
    }
}

export function setCurrentDataTag(data: any) {
    return {
      type: SET_CURRENT_TAG,
      payload: data,
    };
  }

export function getCurrentDataTag({ type, tag }: any = {}): Promise<any> {
    return apiRequest({
        url: `/tutorials/${type}?tag=${tag}`,
        method: "GET"
    });
}

export function setTrainingResources(data: any) {
    return {
      type: SET_TRAINING_RESOURCES,
      payload: data,
    };
  }
export function setExternalLinksResources(data: any) {
    return {
      type: SET_EXTERNAL_LINKS_RESOURCES,
      payload: data,
    };
  }

export function setHomePurchaseResources(data: any) {
    return {
      type: SET_HOME_PURCHASE_RESOURCES,
      payload: data,
    };
  }

export function getHomePurchaseResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=home_purchase`,
        method: "GET"
    });
}

export function getDashboardResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=dashboard`,
        method: "GET"
    });
}

export function getHomeSaleAndPurchaseResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=home_sale_and_purchase`,
        method: "GET"
    });
}

export function getHomeRefinanceResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=home_refinance`,
        method: "GET"
    });
}

export function getMortgageRenewalAndTransferResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=mortgage_renewal_or_transfer`,
        method: "GET"
    });
}

export function getRentalPropertyPurchaseResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=rental_property_purchase`,
        method: "GET"
    });
}

export function getRateComparisonResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=rate_comparison`,
        method: "GET"
    });
}

export function getContactsResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=contacts`,
        method: "GET"
    });
}

export function getAccountSettingsResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=account_settings`,
        method: "GET"
    });
}

export function getSupportAndFeedbackResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=support_and_feedback`,
        method: "GET"
    });
}

export function getReferAndEarnResourcesList(): Promise<any> {
    return apiRequest({
        url: `tutorials/video?tag=refer_and_earn`,
        method: "GET"
    });
}
export function getHomePurchaseExternalLinksList(): Promise<any> {
    return apiRequest({
        url: `/tutorials/link?tag=home_purchase`,
        method: "GET"
    });
}
