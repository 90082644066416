const initialState = false

export function resourceDrawerReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case 'OPEN_DRAWER':
      return payload
    default:
      return state;
  }
}  