import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { APP_REDUCERS } from "./reducers";
const APP_STORE = combineReducers(APP_REDUCERS);
const initialState = {};
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "contact",
    "core",
    "activeTab",
    "firstRender",
    "resourceDrawer",
  ],
};

const persistedReducer = persistReducer(persistConfig, APP_STORE);
export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
