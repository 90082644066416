import apiRequest from "../../@core/axios";

const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS'
const SET_SUBSCRIPTION_PLAN_DETAILS = 'SET_SUBSCRIPTION_PLAN_DETAILS'
const SET_COUPON = 'SET_COUPON'

export const initialState = {
    allSubscriptionPlans: [],
    normalMonthly: [],
    normalYearly: [],
    additionalMonthly: [],
    additionalYearly: [],
    subscriptionPlanDetails: {
        amount: '',
        taxes: [],
        name: '',
        total: '',
        total_tax: '',
    },
    coupon: {},
}

export function SubscriptionPlansReducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case "SET_SUBSCRIPTION_PLANS":
            return {
                ...state,
                allSubscriptionPlans: action.payload,
                normalMonthly: action.payload.filter((plan: any) => {
                    return (
                        // plan.interval === 'month' && !plan.name.includes('Additional')
                        plan.interval === 'month' && !plan.product_identifier.split("_").includes('additional')
                    )
                }),
                normalYearly: action.payload.filter((plan: any) => {
                    return (
                        // plan.interval === 'year' && !plan.name.includes('Additional')
                        plan.interval === 'year' && !plan.product_identifier.split("_").includes('additional')
                    )
                }),
                additionalMonthly: action.payload.filter((plan: any) => {
                    return (
                        // plan.interval === 'month' && plan.name.includes('Additional')
                        plan.interval === 'month' && plan.product_identifier.split("_").includes('additional')
                    )
                }),
                additionalYearly: action.payload.filter((plan: any) => {
                    return (
                        // plan.interval === 'year' && plan.name.includes('Additional')
                        plan.interval === 'year' && plan.product_identifier.split("_").includes('additional')
                    )
                }),
                // features: action.payload.find((p: any) => p.name === 'Elite Tactics Plan')?.features?.map((f: any) => f)
                features: action.payload.find((p: any) => p.product_identifier === 'elite_plan')?.features?.map((f: any) => f)

            };

        case "SET_SUBSCRIPTION_PLAN_DETAILS":
            const subscriptionPlanDetails = {
                amount: action.payload.amount,
                taxes: action.payload.taxes,
                name: action.payload.name,
                total: action.payload.total,
                total_tax: action.payload.total_tax,
            };
            // console.log("detailssss", details)
            return {
                ...state,
                subscriptionPlanDetails,
            };

        case "SET_COUPON":
            return {
                ...state,
                coupon:action.payload,
            };

        default:
            return state;
    }
}

export function setSubscriptionPlans(data: any) {
    return {
        type: SET_SUBSCRIPTION_PLANS,
        payload: data,
    };
}

export function setSubscriptionPlanDetails(data: any) {
    return {
        type: SET_SUBSCRIPTION_PLAN_DETAILS,
        payload: data,
    };
}

export function setPromoCode(data: any) {
    return {
        type: SET_COUPON,
        payload: data,
    };
}

export function getSubscriptionPlanDetails(plan_id: any): Promise<any> {
    return apiRequest({
        url: `plans/${plan_id}`,
        method: "GET",
    });
}


export function getCouponInfo(coupon_promo_code: string) {
    return apiRequest({
        url: `/subscription/coupons/${coupon_promo_code}`,
        method: "GET",
    });
}

export function getSubscriptionPlansList(): Promise<any> {
    return apiRequest({
        url: `plans`,
        method: "GET"
    });
}

export function getSubscriptionInvoiceList(data: any): Promise<any> {
    return apiRequest({
        url: `invoices`,
        method: "GET",
        ...data,
    });
}

export function enableAutoRenewal(subscription_id: any): Promise<any> {
    return apiRequest({
        url: `subscription/${subscription_id}/enable-auto-renewal`,
        method: "POST"
    });
}

export function disableAutoRenewal(subscription_id: any): Promise<any> {
    return apiRequest({
        url: `subscription/${subscription_id}/disable-auto-renewal`,
        method: "POST"
    });
}