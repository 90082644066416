import { RESET_STORE } from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {
  lendersList: [],
};

export function getGlobalRateTypes() {
  return apiRequest({
    url: "rate-types",
    method: "GET",
  });
}
export function getRateComparisonList() {
  return apiRequest({
    url: "rate-comparisons?all=1",
    method: "GET",
  });
}

export function getARateComparison(id: any) {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "GET",
  });
}


export function createRateComparisonPlan(data: any, id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "POST",
    data,
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function duplicateRateComparisonPlan(id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}/duplicate`,
    method: "POST",
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function deleteRateComparisonPlan(data: any = {}, id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "DELETE",
    data,
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function RateComparisonReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
